<template>
  <div class="pageF6Color" ref="churujinListBox">
    <HeadGoBackVue iCustomizdde="true">
      <template #default>
        <span> {{ $fanyi("出入金明细") }} </span>
      </template>
    </HeadGoBackVue>
    <div>
      <div class="churujinListCon" :class="{ TopDown: $route.query.title }">
        <p class="timeChiose" @click="showSheet = true">
          {{ currentYear }}年<van-icon name="arrow-down" />
        </p>
        <p class="amountStatistics" v-if="tableData.length">
          <span
            >{{ $fanyi("出金") }}:
            {{
              $fun.RMBNumSegmentation(
                Math.abs(depositWithdrawalStatistics.withdrawal)
              )
            }}
            円</span
          ><span
            >{{ $fanyi("入金") }}:
            {{ $fun.RMBNumSegmentation(depositWithdrawalStatistics.deposit) }}
            円</span
          >
        </p>
      </div>
      <ul class="tableList">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          head-height="100"
          :loosing-text="$fanyi('释放即可刷新...')"
          :pulling-text="$fanyi('下拉刷新')"
          :loading-text="$fanyi('加载中')"
          :success-text="$fanyi('加载成功')"
        >
          <van-list
            v-model:loading="loading"
            :loading-text="$fanyi('加载中')"
            :finished="finished"
            @load="onLoad"
          >
            <li v-for="(item, index) in tableData" :key="index">
              <div v-if="item.bill_type_name != '充值'">
                <h4>
                  <span>{{ $fanyi(item.bill_type_group) }}</span>
                  <span :class="{ redFont: item.amount > 0 }">
                    {{ item.bill_type_group.indexOf("入金") != -1 ? "+" : "-"
                    }}{{ item.amount }}
                  </span>
                </h4>
                <div class="churujinDetail">
                  <div>
                    <p>{{ $fanyi(item.bill_type_name) }}</p>
                    <p>{{ item.created_at }}</p>
                    <p class="orderSn">
                      <span>{{ $fanyi("注文書") }}：</span>
                      <span class="orderSnFont">{{ item.order_sn }}</span>
                    </p>
                  </div>
                  <div class="userBalance">
                    <p>{{ $fanyi(item.bill_method_name) }}</p>
                    <p class="balanceNum">{{ item.balance }}</p>
                  </div>
                </div>
              </div>
              <div v-else class="rechargeDeposit">
                <h4>
                  <span>{{ $fanyi("充值入金") }}</span>
                  <span class="redFont">
                    {{ item.bill_type_group.indexOf("入金") != -1 ? "+" : "-"
                    }}{{ item.amount }}
                  </span>
                </h4>
                <span class="rujinName">{{ item.bill_method_name }}</span>
                <p class="timeAndAmount">
                  <span>{{ item.created_at }}</span>
                  <span class="amount">{{ item.balance }}</span>
                </p>
              </div>
            </li>
          </van-list>
        </van-pull-refresh>
      </ul>
    </div>
    <div class="noData" v-if="!tableData.length">
      <div>
        <img :src="require('@/assets/icon/crj.png')" alt="" />
        <span>{{ $fanyi("暂无历史记录") }}</span>
      </div>
    </div>
    <van-action-sheet v-model:show="showSheet">
      <div class="timeCheck">
        <div class="pickBoxHead">
          {{ $fanyi("年份选择") }}
          <img
            @click="pickerCancel"
            :src="require('@/assets/icon/tanchaunbgchahao.png')"
            alt=""
          />
        </div>
        <van-datetime-picker
          v-model="piceTime"
          class="yearNN"
          type="year-month"
        />
        <div class="btnBox">
          <div class="comfigBtn" @click="pickerConfirm">
            {{ $fanyi("确认") }}
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import HeadGoBackVue from "@/components/headGoBack";
import { getCurrentInstance, ref, onMounted } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
import { specifiedFormatDate } from "../../../../utlis/date.js";

let newYear = new Date().getFullYear();

//------------------------ data -------------------------------------
const showSheet = ref(false);
// 加载数据
const loading = ref(false);
const finished = ref(false);
const refreshing = ref(false);
const piceTime = ref(new Date());
// 选择的年份
const currentYear = ref(newYear);
// 出入金统计数据
const depositWithdrawalStatistics = ref({
  withdrawal: 0, //出金
  deposit: 0, // 入金
});
// 表格数据
const tableData = ref([]);
// 请求接口参数
const datas = ref({
  pageSize: 10,
  page: 0,
  start_time: `${specifiedFormatDate({ format: "yyyy" })}-1-1`,
  end_time: `${specifiedFormatDate({ format: "yyyy" })}-12-31`,
  keywords: proxy.$route.query.keywords || "",
});
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
const onRefresh = () => {
  if (finished.value == true) {
    Toast(proxy.$fanyi("没有更多了"));
    refreshing.value = false;
  } else {
    resetTheTableData();
    onLoad();
  }
};
//------------------------ methods -------------------------------------
// 重置表格数据
const resetTheTableData = () => {
  datas.value.page = 0;
  tableData.value = [];
};
// 获取出入金统计数据
const getDepositWithdrawalStatistics = () => {
  proxy.$api.balanceStatistics(datas).then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) {
      returnproxy.$message.error(proxy.$fanyi(res.data.msg));
    }
    // //接下来的操作
    // console.log(
    //   res.data.collect
    //     .find((item) => {
    //       return item.type_name == "入金";
    //     })
    //     .status_detail.find((item) => {
    //       return item.status_name == "已审核";
    //     }).sum_amount
    // );
    depositWithdrawalStatistics.value.deposit = res.data.collect
      .find((item) => {
        return item.type_name == "入金";
      })
      .status_detail.find((item) => {
        return item.status_name == "已审核";
      }).sum_amount;
    depositWithdrawalStatistics.value.withdrawal = res.data.collect
      .find((item) => {
        return item.type_name == "出金";
      })
      .status_detail.find((item) => {
        return item.status_name == "已审核";
      }).sum_amount;
  });
};
getDepositWithdrawalStatistics();
// 加载
const onLoad = async () => {
  datas.value.page++;
  const res = await proxy.$api.balanceChange(datas.value);
  if (res.code !== 0) {
    finished.value = true;
    Toast.fail(proxy.$fanyi(res.msg));
    Toast.clear();
  }
  loading.value = false;
  Toast.clear();
  // 进项判断是上下拉还是上划
  if (refreshing.value == true) {
    tableData.value = [...res.data.data, ...tableData.value];
    refreshing.value = false;
  } else {
    tableData.value = [...tableData.value, ...res.data.data];
  }
  if (
    tableData.value.length == res.data.total ||
    res.data?.data.length < datas.value.pageSize
  ) {
    finished.value = true;
  }
};
const pickerCancel = () => {
  showSheet.value = false;
};
const pickerConfirm = (val) => {
  finished.value = false;
  resetTheTableData();
  let data = new Date(piceTime.value);
  currentYear.value = data.getFullYear();
  showSheet.value = false;
  datas.value.start_time = currentYear.value + "-1-1";
  datas.value.end_time = currentYear.value + "-12-31";
  onLoad();
  getDepositWithdrawalStatistics();
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageF6Color {
  height: 100vh;
  overflow: auto;
}

.redFont {
  color: #b4272b;
}

.churujinListCon {
  position: sticky;
  top: 100px;
  &.TopDown {
    top: 0;
  }
  padding: 20px 30px;
  background-color: #f6f6f6;
  z-index: 3;
  .timeChiose {
    display: flex;
    align-items: center;
    font-size: 28px;
    margin: 3px 0 18px;

    .van-icon-arrow-down {
      font-size: 20px;
    }
  }

  .amountStatistics {
    display: flex;
    align-items: center;
    span {
      font-size: 24px;
      color: #999999;
      line-height: 1;
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tableList {
  margin: 0 30px;
  padding-bottom: 30px;

  li {
    width: 690px;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 15px;

    h4 {
      width: 110px;
      height: 27px;
      display: flex;
      line-height: 1;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        font-size: 28px;
      }
    }

    .churujinDetail {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #999999;

      p {
        font-size: 24px;
        margin: 24px 0;
        line-height: 1;
      }

      .orderSn {
        margin-top: 41px;
        margin-bottom: 0;

        span {
          font-size: 24px;
        }

        .orderSnFont {
          color: #010101;
          font-size: 24px;
        }
      }

      .userBalance {
        text-align: right;

        p {
          margin: 11px 0 0;
        }

        p.balanceNum {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .rechargeDeposit {
      h4 {
        margin-bottom: 20px;
        span {
          font-size: 28px;
          font-weight: 400;
          line-height: 30px;
          color: #000000;
        }
        .redFont {
          font-size: 30px;
          font-weight: 600;
          color: #b4272b;
        }
      }
      .rujinName {
        display: block;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 10px;
        color: #999999;
        text-align: right;
      }
      .timeAndAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #999999;
        }
        span.amount {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
  }
}
.noData {
  padding-top: 181px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 265px;
      margin-bottom: 50px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
<style lang="scss">
.timeCheck {
  position: relative;
  .pickBoxHead {
    line-height: 1;
    padding: 45px 0 40px;
    text-align: center;
    font-size: 30px;
    position: relative;
    img {
      position: absolute;
      right: 30px;
      top: 25px;
      width: 18px;
      height: 18px;
    }
  }
  .yearNN {
    .van-picker__columns {
      .van-picker-column {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .van-picker__toolbar {
      height: 0;
      display: none;
    }
  }
  .btnBox {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    .comfigBtn {
      width: 690px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
